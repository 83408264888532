import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squats 4×6\\@80% 1RM`}</p>
    <p>{`DB RDL’s 4×6`}</p>
    <p>{`then,`}</p>
    <p>{`21-Squat Cleans (95/65)`}</p>
    <p>{`21-Bar Facing Burpees`}</p>
    <p>{`15-Squat Cleans (115/75)`}</p>
    <p>{`15-Bar Facing Burpees`}</p>
    <p>{`9-Squat Cleans (135/95)`}</p>
    <p>{`9-Bar Facing Burpees`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This coming Thursday is the 4th of July.  We will have our FREE
class at The Ville at 10:00am.  This will be our only class on the 4th,
all other classes and open gym will be cancelled so come out and join in
the fun!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      